import { type MouseEvent, useRef, useEffect } from "react";
import { useAuth } from "ict24h-themes-react";
import getGraphAccessToken from "../../utils/get-graph-token";
import "./RenderIframe.css";
import { useNavigate } from "react-router-dom";

export interface IframeProps {
  module: {
    childOrigin: string;
    url: string;
  };
}

const handleContextmenu = (e: MouseEvent<HTMLIFrameElement>) => {
  e.preventDefault();
};

export default function RenderIframe({ module }: IframeProps) {
  const iRef = useRef<HTMLIFrameElement | null>(null);
  const auth = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("message", parenMsgListener);
  }, []);

  function parenMsgListener(event: MessageEvent<any>) {
    // cần phải lọc bớt, sao có nhiều request lạ quá
    // if (event.origin !== module.childOrigin) {
    //   return;
    // }

    // just handle ict24h msg
    if (event.data.type?.substring(0, 7) !== "ict24h-") {
      return;
    }

    // console.log("parent da nhan duoc: ", event.data);
    if (event.data.type === "ict24h-auth-request") {
      // console.log(" Parent đã nhận đc: ict24h-auth-request");
      sendAuthData();
    }
    // // Xử lý khi có tin nhắn logout
    if (event.data.type === "ict24h-logout") {
      console.log("đã nhận đc: ict24h-logout");
    }

    if (event.data.type === "ict24h-ms-token") {
      sendMsTokenData();
    }

    if (event.data.type === "ict24h-parent-navigate") {
      handleParentNavigate(event.data.payload);
    }
  }

  const handleParentNavigate = (path?: string) => {
    if (typeof path === "string") {
      navigate(path);
    }
  };

  const handleLoad = () => {
    if (iRef?.current?.contentWindow) {
      setTimeout(() => {
        iRef.current?.contentWindow?.postMessage("hello", "*");
      }, 100);
    }
  };

  async function sendAuthData() {
    if (iRef?.current?.contentWindow) {
      const msToken = await getGraphAccessToken();
      const payload = {
        currentUser: {},
        msToken,
        token: auth.token,
      };
      const dataToSend = {
        type: "ict24h-auth",
        payload: payload,
      };

      const encryptedData = localStorage.getItem("i24h-dev-approval-auth");
      if (encryptedData) {
        iRef.current?.contentWindow.postMessage(
          {
            type: "ict24h-auth-encrypted",
            payload: encryptedData,
          },
          "*"
        );
      }
      // console.log("parent sendMessage: ", dataToSend);
      //   iFrame.contentWindow.postMessage(dataToSend, "*" );
      iRef.current?.contentWindow.postMessage(dataToSend, "*");
    }
  }
  async function sendMsTokenData() {
    if (iRef?.current?.contentWindow) {
      const msToken = await getGraphAccessToken();
      const payload = {
        msToken,
      };
      const dataToSend = {
        type: "ict24h-ms-token",
        payload,
      };
      // console.log("parent sendMessage: ", dataToSend);
      //   iFrame.contentWindow.postMessage(dataToSend, "*" );
      iRef.current?.contentWindow.postMessage(dataToSend, "*");
    }
  }

  return (
    <iframe
      src={module.childOrigin + module.url}
      ref={iRef}
      onLoad={handleLoad}
      onContextMenu={handleContextmenu}
      title="Module"
      translate="yes"
      className="hidden-scrollbar"
      style={{
        border: 0,
        width: "100%",
        height: "99dvh",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
      allow=" clipboard-read; clipboard-write *"
      allowFullScreen
    />
  );
}
