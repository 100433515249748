import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "antd";
import { CalendarOutlined, NotificationOutlined } from "@ant-design/icons";

const dayInWeek = [
  "Chủ nhật",
  "Thứ 2",
  "Thứ 3",
  "Thứ 4",
  "Thứ 5",
  "Thứ 6",
  "Thứ 7",
];
const HomeCreatingTime = () => {
  const [time, setTime] = useState(new Date());
  const [inDayTime, setInDayTime] = useState("");

  const refreshTime = () => setTime(new Date());

  useEffect(() => {
    let timerId = setInterval(refreshTime, 1000);
    return () => clearInterval(timerId);
  }, []);

  // tính buổi trong ngày theo giờ
  useEffect(() => {
    const hours = time.getHours();
    if (hours >= 4 && hours < 12) {
      const text = "sáng";
      if (inDayTime !== text) {
        setInDayTime(text);
      }
    } else if (hours >= 12 && hours < 13) {
      const text = "trưa";
      if (inDayTime !== text) {
        setInDayTime(text);
      }
    } else if (hours >= 13 && hours < 18) {
      const text = "chiều";
      if (inDayTime !== text) {
        setInDayTime(text);
      }
    } else {
      const text = "tối";
      if (inDayTime !== text) {
        setInDayTime(text);
      }
    }
  }, [time]);

  // thêm số 0 vào trước nếu số nhỏ hơn 9
  const addZeroFirst = (numb: number) => (numb < 10 ? `0${numb}` : numb);

  return (
    <>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <div style={{ paddingLeft: 20, paddingTop: 10 }}>
            <h3>Chào buổi {inDayTime},</h3>
            <p>🤩 Chúc bạn một ngày tốt lành!</p>
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} lg={12}>
          <div style={{ paddingRight: 20, textAlign: "right" }}>
            <span
              style={{
                fontSize: "2rem",
              }}
            >
              {addZeroFirst(time.getHours())}:{addZeroFirst(time.getMinutes())}
            </span>{" "}
            {addZeroFirst(time.getSeconds())}
            <br />
            {dayInWeek[time.getDay()]}, {addZeroFirst(time.getDate())}/
            {addZeroFirst(time.getMonth() + 1)}/{time.getFullYear()}
          </div>
        </Col>
      </Row>

      <Row
        style={{ paddingTop: 15 }}
        gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}
      >
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card
            size="small"
            title="Thông báo mới"
            extra={<a href="#">Xem thêm</a>}
            style={{ width: "100%" }}
          >
            <p>
              <Badge dot>
                <NotificationOutlined />
              </Badge>{" "}
              <a href="#">Thông báo về kế hoạch điều chính lương năm 2025</a>
            </p>
            <p>
              <NotificationOutlined />{" "}
              <a href="#">
                Thông báo đưa vào sử dụng Văn phòng điện tử eOffice{" "}
              </a>
            </p>
            <p>
              <NotificationOutlined />{" "}
              <a href="#">
                Đăng ký Bảo hiểm sức khỏe người thân (tự nguyện) 2025-2026
              </a>
            </p>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card
            size="small"
            title="Sự kiện công ty sắp diễn ra"
            extra={<a href="#">Xem thêm</a>}
            style={{ width: "100%" }}
          >
            <p>
              <CalendarOutlined />{" "}
              <a href="#">Đăng ký team building hè 2025 - Mũi Né</a>
            </p>
            <p>
              <CalendarOutlined />{" "}
              <a href="#">Tổ chức trung thu cho con em trong công ty</a>
            </p>
            <p>
              <CalendarOutlined />{" "}
              <a href="#">Bão dưỡng tổ hợp máy số 1 ở phân xưởng X</a>
            </p>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HomeCreatingTime;
