import { SidebarLeftLayout } from "ict24h-themes-react";
import { dockConfig } from "./dock-config";

import React from "react";

import styles from "./Home.module.css";
import HomeListApp from "./components/home-list-app";
import HomeCreatingTime from "./components/home-greating-time";
import isInICTMobileApp from "../../utils/is-ict-mobile-app";

const isInMobileApp = isInICTMobileApp();

const AppHome: React.FC = () => {
  // @ts-ignore
  return (
    <div
    // style={{
    //   maxHeight: "calc(100vh - 100px)",
    //   width: "100%",
    //   overflowY: "auto",
    // }}
    >
      <div className={styles.containerBg}>
        <div className={styles.container}>
          <br />
          <div className={styles.appsContainerOuter}>
            <div className={styles.appsContainer}>
              <div className={styles.cardBgOpacity}>
                <HomeCreatingTime />
              </div>
              <HomeListApp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHome;

// export default function HomeDashboard(){
//
// 	return <SidebarLeftLayout menu={null} dock={dockConfig}>
// 		<div>
// 			<div>
// 				Chào buổi _buoi_ <br />
// 				Ngày giờ <br />
// 				Tin tức ở đây nha <br />
// 			</div>
// 			<div>
// 				<p>
// 					<Link to="/sp-files">Files</Link>
// 				</p>
// 				<p>
// 					<Link to={QUAN_LY_PHE_DUYET_PREFIX_URL}>QL Phê duyệt</Link>
// 				</p>
// 				<p>
// 					<Link to={QUAN_LY_DAO_TAO_PREFIX_URL}>QL Đào tạo</Link>
// 				</p>
// 				<p>
// 					<a href="https://contract-to-customer-public-view.i24h.app/#/config">Hợp đồng thương thảo với khách hàng</a>
// 				</p>
// 				<p>
// 					<a href="https://i24h.sharepoint.com/sites/dev2/customer-view/phuc-thinh/apps/approval/default.aspx#/approval">Quy trình phúc thịnh</a>
// 				</p>
// 				<p>portal</p>
// 				<p>icons</p>
// 				<p>
// 					<a href="https://google.com/" target="_blank">Google</a>
// 				</p>
// 			</div>
//
// 		</div>
// 	</SidebarLeftLayout>
//
//
// }
