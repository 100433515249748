import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RenderIframe, { IframeProps } from "./RenderIframe";

const defaultModule: IframeProps["module"] = {
  childOrigin: "https://localhost:3000",
  url: "/test-external",
};

const hrmDomain = "https://i24h-m365-hrm.pages-dev.i24h.app";
// const hrmDomain = "https://localhost:5173";

const initListModules: { [key: string]: IframeProps["module"] } = {
  default: defaultModule,
  "hrm-my-profile": {
    childOrigin: hrmDomain,
    url: "/hrm/view-employee/cau-hinh-thong-tin/nhan-vien/my-page",
  },
  "hrm-kpi": {
    childOrigin: hrmDomain,
    url: "/hrm/view-employee/danh-gia-kpi/dashboard-kpi",
  },
  "hrm-nghi-phep": {
    childOrigin: hrmDomain,
    url: "/hrm/view-employee/nghi-phep-nam/bao-cao-cua-toi",
  },
  "hrm-tai-san": {
    childOrigin: hrmDomain,
    url: "/hrm/quan-ly-tai-san/tai-san",
  },
  "hrm-tinh-luong": {
    childOrigin: hrmDomain,
    url: "/hrm/bang-luong/bao-cao",
  },
  "hrm-cham-cong": {
    childOrigin: hrmDomain,
    url: "/hrm/view-employee/cham-cong/cham-cong-cua-toi",
  },
  "hrm-phong-van": {
    childOrigin: hrmDomain,
    url: "/hrm/view-hr/quan-ly-tuyen-dung/bao-cao",
  },
  "hrm-bang-cham-cong": {
    childOrigin: hrmDomain,
    url: "/hrm/view-hr/cham-cong/bang-cham-cong-nhan-vien",
  },
  "hrm-quan-ly-dao-tao": {
    childOrigin: hrmDomain,
    url: "/hrm/view-employee/quan-ly-dao-tao/khoa-dao-tao/dang-hoc",
  },
  "hrm-dieu-chuyen-nhan-su": {
    childOrigin: hrmDomain,
    url: "/hrm/quan-ly-dieu-dong-luan-chuyen/ke-hoach-luan-chuyen",
  },
  "hrm-ung-vien": {
    childOrigin: hrmDomain,
    url: "/hrm/view-hr/quan-ly-tuyen-dung/ung-vien",
  },
  "hrm-tuyen-dung": {
    childOrigin: hrmDomain,
    url: "/hrm/view-hr/quan-ly-tuyen-dung/vi-tri-tuyen-dung",
  },
};

export default function ExternalComponent() {
  const { moduleName } = useParams();

  const [currentModule, setCurrentModule] = useState<string>("default");
  const [listModule, setListModule] = useState(initListModules);

  //load list config from local storage
  useEffect(() => {
    const listModules = localStorage.getItem("listModules");
    if (listModules) {
      setListModule({
        ...JSON.parse(listModules),
        ...initListModules,
      });
    }
  }, []);

  useEffect(() => {
    if (moduleName) {
      setCurrentModule(moduleName);
    }
  }, [moduleName]);

  return <RenderIframe module={listModule[currentModule]} />;
}
