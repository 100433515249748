import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import axios from "axios";
import showConsentDialog from "../../../utils/show-consent-dialog";

export default function Login() {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();

  const ssoLoginSuccess = async (result: string) => {
    exchangeClientTokenForServerToken(result);
  };

  const ssoLoginFailure = (error: string) => {
    console.error("Login failed: ", error);
  };

  // call when rendering the component
  // initLoginToken(ssoLoginSuccess,ssoLoginFailure, setCurrentUser, setLoading).catch(error => console.error(error));
  useEffect(() => {
    console.log("initLoginToken called");
    // Initialize the Microsoft Teams SDK
    microsoftTeams.app.initialize().then(() => {
      // Get the user context from Teams and set it in the state
      microsoftTeams.app
        .getContext()
        .then((context: microsoftTeams.app.Context) => {
          setCurrentUser(context?.user as any);
        });

      microsoftTeams.authentication
        .getAuthToken()
        .then((result: string) => {
          ssoLoginSuccess(result);
          setLoading(false);

          console.log(
            ">> navigateToTab: ",
            microsoftTeams.pages.tabs.navigateToTab
          );

          // navigate to Portal tab
          microsoftTeams.pages.tabs.navigateToTab({
            entityId: "ict24h.m365.tabs.portal",
            tabName: "Portal",
          });
        })
        .catch((error: string) => {
          ssoLoginFailure(error);
        });
    });
  }, []);

  async function exchangeClientTokenForServerToken(clientToken: string) {
    const { data } = await axios.post(
      "https://approval.zbh-dev.i24h.app/auth/login-ms-obh",
      {
        token: clientToken,
        clientId: process.env.REACT_APP_MSAL_APP_ID,
      }
    );

    // phongf truowngf hopwj caanf consent
    // @ts-ignore
    if (
      data?.error &&
      data?.error[0]?.response?.errrorCode === "consent_required"
    ) {
      showConsentDialog();
    }

    console.log("callGetTokens: ", data);
  }

  return (
    <div>
      {
        // @ts-ignore
        currentUser?.userPrincipalName ? (
          <p>
            Tài khoản:{" "}
            {
              // @ts-ignore
              currentUser.userPrincipalName
            }
          </p>
        ) : (
          <button onClick={showConsentDialog}>Mở cửa sổ xác thực</button>
        )
      }

      {loading ? null : <p>Đang kiểm tra tài khoản ...</p>}
    </div>
  );
}
